<template>
  <div>
    <div class="loadingBox" v-if="loadingShow">
      加载中
      <img class="loadingMove" src="../assets/loading.png" alt="" />
    </div>
    <vue-office-docx
      v-if="PDFANDDOCXANDIMG == 'DOCX'"
      :src="src"
      style="height: 100vh"
      @rendered="renderedDocx"
    />
    <vue-office-pdf
      v-else-if="PDFANDDOCXANDIMG == 'PDF'"
      :src="src"
      @rendered="pdfrenderedHandler"
      @error="pdferrorHandler"
    />
  </div>
</template>

<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from "@vue-office/docx";
import VueOfficePdf from "@vue-office/pdf";

//引入相关样式
import "@vue-office/docx/lib/index.css";

export default {
  props: {
    src: String,
  },
  components: {
    VueOfficeDocx,
    VueOfficePdf,
  },
  data() {
    return {
      PDFANDDOCXANDIMG: "DOCX", // PDF/DOCX/IMG
      loadingShow: true,
    };
  },
  methods: {
    renderedDocx() {
      this.loadingShow = false;
      this.$emit("close");
      console.log("docx渲染完成");
    },
    pdfrenderedHandler() {
      this.loadingShow = false;
      this.$emit("close");
      console.log("pdf渲染完成");
    },
    pdferrorHandler() {
      this.loadingShow = false;
      this.$emit("close");
      console.log("pdf渲染失败");
    },
  },
  watch: {
    src: {
      handler(val) {
        let ext = val.substr(val.lastIndexOf(".") + 1);
        this.loadingShow = true;
        this.$emit("open");
        switch (ext) {
          case "pdf":
            this.PDFANDDOCXANDIMG = "PDF";

            break;
          case "docx":
            this.PDFANDDOCXANDIMG = "DOCX";

            break;
          default:
            break;
        }
        this.src = val;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.loadingBox {
  width: 100%;
  height: 100vh;
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: rgb(0, 173, 253);
  background: rgba(0, 0, 0, 0.7);
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}
.loadingMove {
  animation: loadingMove 1s linear infinite;
}
@keyframes loadingMove {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.vue-office-pdf {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.docx-wrapper {
  background: white !important;
}
</style>
